import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import React from 'react'

export interface PreviousButtonProps {
  onClick: () => void
}

const PreviousButton = ({ onClick }: PreviousButtonProps) => (
  <IconButton
    variant="text"
    type="button"
    className="hover:-translate-x-0.5 transition-transform"
    iconClassName="h-6 w-6 mr-2 fill-primary-500"
    Icon={Icons.ArrowLeft}
    onClick={onClick}
    tabIndex={2}
  />
)

export default PreviousButton
